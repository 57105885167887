import { sortByKey, ss } from "consts";
import { useMap } from "hooks";
import {
  AttendanceModel,
  BaseProviderType,
  PermissionProviderType,
  ServicePathEnum,
  SortByEnum,
} from "models";
import { useProfile, useTelemetry, useToast } from "providers";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import * as AttendanceService from "services/attendance";
import {
  audioNotification,
  aytyFormatError,
  milisecondsToTime,
  notification,
} from "utils";
import { DataErrors } from "./errors";
import { Telemetry } from "classes";

const AudioPath = require("assets/audios/notification.mp3");

type AttendanceMap = Map<number, AttendanceModel>;

type AttendanceContextType = {
  loading: boolean;
  averageTime: number;
  attendances?: AttendanceModel[];
  hasAttendance: boolean;
  queueAmount?: number;
  sortBy?: SortByEnum;
  getAttendances: (isReload?: boolean) => void;
  getAttendance: (id: number) => AttendanceModel | undefined;
  removeAttendance: (key: number) => Promise<AttendanceMap | undefined>;
  verifyFinishAuto: () => void;
  onEditInRedial: (value: boolean) => void;
  changeSort: (sort: SortByEnum) => void;
};

const AttendanceContext = createContext({} as AttendanceContextType);

const { lastAttendannce, lastMessage } = SortByEnum;

const initialSort = ss.getItem(sortByKey) as SortByEnum | null;

const maxTimes = 5;

const Provider = ({ children }: BaseProviderType) => {
  const [loading, setLoading] = useState(true);
  const [inRedial, setInRedial] = useState(false);
  const [listingTimes, setListingTimes] = useState<Array<number>>([]);
  const [queueAmount, setQueueAmount] = useState<number>();
  const [list, { set, remove }] = useMap<number, AttendanceModel>();
  const [sortBy, setSortBy] = useState<SortByEnum>(
    initialSort ?? SortByEnum.lastMessage
  );
  const { pIdProject, user } = useProfile();
  const { addTelemetry } = useTelemetry();
  const { error, warning } = useToast();
  const { t } = useTranslation();

  const errorsResolver = useMemo(
    () => new DataErrors({ error, warning }, t),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const hasAttendance = useMemo(
    () => !!user?.hasAccessAttend && !!list?.size,
    [user, list]
  );

  const attendances = useMemo(() => {
    const isDesc = [lastAttendannce, lastMessage].includes(sortBy);

    if (list)
      return Array.from(list.values()).sort((a, b) => {
        let result = 0;

        let currentA: Date;
        let currentB: Date;

        switch (sortBy) {
          case SortByEnum.lastAttendannce:
          case SortByEnum.oldAttendance:
            currentA = new Date(a.DtStart);
            currentB = new Date(b.DtStart);
            break;
          case SortByEnum.lastMessage:
          case SortByEnum.oldMessage:
            currentA = new Date(a.DtLastMessage || a.DtStart);
            currentB = new Date(b.DtLastMessage || b.DtStart);
            break;
        }

        if (currentA > currentB) result = 1;
        else if (currentA < currentB) result = -1;

        return isDesc ? result * -1 : result;
      });
  }, [list, sortBy]);

  const averageTime = useMemo(() => {
    const sum = listingTimes.reduce((total, value) => total + value, 0);
    return sum / listingTimes.length || 0;
  }, [listingTimes]);

  const getAttendances = useCallback(
    (isReload = false) => {
      const telemetry = new Telemetry(ServicePathEnum.getAttendances);

      try {
        if (pIdProject) {
          const reqDate = Date.now();

          const result = AttendanceService.getAttendancesSync({ pIdProject });

          const resDate = Date.now();

          setListingTimes((current) => [
            resDate - reqDate,
            ...current.slice(0, maxTimes - 1),
          ]);

          if (result.idReturnAPI > 0) {
            set((current) => {
              let count = 0;

              const currentMap = new Map(
                result.omniOnLineList?.map((o) => {
                  if (current && !current.has(o.IdOmni)) count += 1;

                  return [o.IdOmni, o];
                })
              );

              if (!isReload && count > 0) {
                audioNotification(AudioPath);
                notification(
                  t("newAttendance.title"),
                  t("newAttendance.description")
                );
              }

              return currentMap;
            });
          } else warning({ description: t(aytyFormatError(result)) });

          setQueueAmount(result.qtUserOmniRealtimeList);

          addTelemetry(telemetry.finish(), result, {
            qtMSecDurationGetOmniListOnLine: milisecondsToTime(
              result.qtMSecDurationGetOmniListOnLine
            ),
            qtMSecDurationVerifyIfHasMailingAPIDialerAPI: milisecondsToTime(
              result.qtMSecDurationVerifyIfHasMailingAPIDialerAPI
            ),
            idReturnAPIMailingAPI: result.idReturnAPIMailingAPI,
          });
        }
      } catch (e: unknown) {
        if (e instanceof Error) {
          errorsResolver.defaultSyncError(e);
          addTelemetry(telemetry.finish(), e);
        } else console.log("GetAttendances", e);
      } finally {
        setLoading(false);
      }
    },
    [pIdProject, errorsResolver, set, warning, t, addTelemetry]
  );

  const verifyFinishAuto = useCallback(() => {
    if (!inRedial) {
      const telemetry = new Telemetry(ServicePathEnum.verifyFinishAuto);

      try {
        if (pIdProject) {
          const result = AttendanceService.verifyFinishAutoSync({
            pIdProject,
          });

          if (result.idReturnAPI <= 0)
            warning({ description: t(aytyFormatError(result)) });

          addTelemetry(telemetry.finish(), result);
        }
      } catch (e: unknown) {
        if (e instanceof Error) {
          errorsResolver.defaultSyncError(e);
          addTelemetry(telemetry.finish(), e);
        } else console.log("verifyFinishAuto", e);
      }
    }
  }, [inRedial, pIdProject, errorsResolver, warning, t, addTelemetry]);

  const onEditInRedial = useCallback(
    (value: boolean) => setInRedial(value),
    []
  );

  const changeSort = useCallback((sort: SortByEnum) => {
    setSortBy(sort);
    ss.setItem(sortByKey, sort);
  }, []);

  const getAttendance = useCallback((id: number) => list?.get(id), [list]);

  return (
    <AttendanceContext.Provider
      value={{
        loading,
        averageTime,
        attendances,
        hasAttendance,
        queueAmount,
        sortBy,
        getAttendances,
        getAttendance,
        removeAttendance: remove,
        verifyFinishAuto,
        onEditInRedial,
        changeSort,
      }}
    >
      {children}
    </AttendanceContext.Provider>
  );
};

export const AttendanceProvider = ({
  hasPermission = true,
  ...args
}: PermissionProviderType) => {
  if (!hasPermission) return <>{args.children}</>;

  return <Provider {...args} />;
};

export const useAttendance = () => useContext(AttendanceContext);
