import { OptionType } from "models";

// Values
export const customPeriod = 0;

export const dayFormat = "yyyy-MM-dd";

export const fiveSeconds = 5000;

export const fullDateTimeFormat = "yyyy-MM-dd HH:mm:ss";

export const fullTimeFormat = "HH:mm:ss";

export const fullToLocaleFormat = "dd/MM/yyyy HH:mm:ss";

export const halfASecond = 500;

export const ISOFormat = "yyyy-MM-dd'T'HH:mm";

export const minDate = "1950-01-01T00:00";

export const oneSecond = 1000;

export const oneMinute = 60000;

export const tenSeconds = 10000;

export const timeFormat = "HH:mm";

export const toastTime = 3000;

export const todayPeriod = 1;

export const toLocaleDateFormat = "dd/MM/yyyy";

export const toLocaleFormat = "dd/MM/yyyy HH:mm";

export const threeSecond = 3000;

export const yesterdayPeriod = -1;

// Objects

export const formatDistanceLocale: { [x: string]: any } = {
  lessThanXSeconds: "{{count}}s",
  xSeconds: "{{count}}s",
  halfAMinute: "30s",
  lessThanXMinutes: "{{count}}m",
  xMinutes: "{{count}}m",
  aboutXHours: "{{count}}h",
  xHours: "{{count}}h",
  xDays: "{{count}}d",
  aboutXWeeks: "{{count}}sem.",
  xWeeks: "{{count}}sem.",
  aboutXMonths: "{{count}}mês",
  xMonths: "{{count}}mês",
  aboutXYears: "{{count}}ano",
  xYears: "{{count}}ano",
  overXYears: "{{count}}ano",
  almostXYears: "{{count}}ano",
};

export const periodOptions: OptionType<number>[] = [
  { value: customPeriod, label: "periodOption.customPeriod" },
  { value: todayPeriod, label: "periodOption.today" },
  { value: yesterdayPeriod, label: "periodOption.yesterday" },
  { value: 6, label: "periodOption.latest7Days" },
  { value: 14, label: "periodOption.latest15Days" },
  { value: 29, label: "periodOption.latest30Days" },
];
