import { AxiosError } from "axios";
import { pathDivider } from "consts";
import { CreateToastType } from "providers";

type ToastType = {
  error: CreateToastType;
  warning: CreateToastType;
};

export class Errors {
  error: CreateToastType;
  warning: CreateToastType;
  t: Function;

  constructor({ error, warning }: ToastType, translation: Function) {
    this.error = error;
    this.warning = warning;
    this.t = translation;
  }

  defaultError = (err: AxiosError) => {
    const paths = (err.request?.responseURL ?? "").split(pathDivider);

    let details = `\n${err.message}`;
    if (err.response)
      details = `\n${err.response?.status} - ${paths[paths.length - 1]}`;

    switch (err.response?.status) {
      case 401:
        this.warning({
          description: this.t("alerts.unauthorizedAction") + details,
        });
        break;
      case 500:
        this.error({
          description: this.t("errors.interruptedAction") + details,
        });
        break;
      default:
        this.error({
          description: this.t("errors.unforeseen") + details,
        });
        break;
    }

    console.log(`Erro: ${err}`);
  };

  defaultSyncError = (err: Error) => {
    const cause = err.cause as XMLHttpRequest | undefined;
    const paths = (cause?.responseURL ?? "").split(pathDivider);

    let details = `\n${err.message}`;
    if (cause) details = `\n${cause?.status} - ${paths[paths.length - 1]}`;

    this.error({ description: this.t("errors.unforeseen") + details });

    console.log(`Erro: ${err}`);
  };
}
