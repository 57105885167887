import { yupResolver } from "@hookform/resolvers/yup";
import { ModalParamsType } from "models";
import { useProfile } from "providers";
import { useCallback } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SelectProjectSchema } from "utils";

type SelectProjectFormType = {
  project: number;
};

export default function SelectProjectViewModel({ onClose }: ModalParamsType) {
  const {
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<SelectProjectFormType>({
    resolver: yupResolver(SelectProjectSchema),
  });
  const { pIdProject, projectOpts, onChangePIdProject } = useProfile();
  const { t } = useTranslation("modals");
  const navigate = useNavigate();

  const submit: SubmitHandler<SelectProjectFormType> = useCallback(
    (data) => {
      onChangePIdProject(data.project);
      navigate("/");
      onClose();
    },
    [onChangePIdProject, navigate, onClose]
  );

  return {
    errors,
    pIdProject,
    projectOpts,
    t,
    setValue,
    onSubmit: handleSubmit(submit),
  };
}
