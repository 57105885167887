import { AxiosError } from "axios";
import { ls, telemetryKey } from "consts";
import { BaseProviderType, BaseReponseType, externalPathEnum } from "models";
import { BaseTelemetryModel, TelemetryModel } from "models/telemetry";
import { createContext, useCallback, useContext, useState } from "react";
import { windowOpenSessionCleaner } from "utils";

type TelemetriesType = {
  [name: string]: TelemetryModel[];
};

type TelemetryContextType = {
  telemetries: TelemetriesType;
  loading: boolean;
  updateStorage: () => void;
  addTelemetry: (
    telemetry: BaseTelemetryModel,
    result: BaseReponseType | AxiosError | Error,
    customFields?: Object
  ) => void;
  onOpenTelemetry: () => void;
};

const TelemetryContext = createContext<TelemetryContextType>(
  {} as TelemetryContextType
);

const maxTelemetry = 100;

const allTelemetries = JSON.parse(ls.getItem(telemetryKey) ?? "{}");

const emptyTelemetry: TelemetryModel = {
  uid: "",
  path: "",
  startDate: "",
  endDate: "",
  interval: "",
  message: "",
  status: "",
  error: "",
};

let telemetryWindow: Window | null;

export const TelemetryProvider = ({ children }: BaseProviderType) => {
  const [loading, setLoading] = useState(false);
  const [telemetries, setTelemetries] =
    useState<TelemetriesType>(allTelemetries);

  const updateStorage = useCallback(() => {
    setLoading(true);
    setTelemetries(JSON.parse(ls.getItem(telemetryKey) ?? "{}"));
    setLoading(false);
  }, []);

  const setInStorage = useCallback((list: TelemetriesType) => {
    try {
      ls.setItem(telemetryKey, JSON.stringify(list));
    } catch (err) {
      console.error(err);
    }
  }, []);

  const onAdd = useCallback(
    (telemetry: TelemetryModel) => {
      const list = telemetries;
      let current: TelemetryModel[] = list[telemetry.path] ?? [];

      current = [telemetry, ...current.slice(0, maxTelemetry - 1)];
      list[telemetry.path] = current;

      setTelemetries(list);
      setInStorage(list);
    },
    [telemetries, setInStorage]
  );

  const addTelemetry = useCallback(
    (
      telemetry: BaseTelemetryModel,
      result: BaseReponseType | AxiosError | Error,
      customFields?: Object
    ) => {
      if (result instanceof AxiosError)
        onAdd({
          ...emptyTelemetry,
          ...telemetry,
          status: result.response?.status,
          error: result.message,
        });
      else if (result instanceof Error) {
        const cause = result.cause as XMLHttpRequest | undefined;
        onAdd({
          ...emptyTelemetry,
          ...telemetry,
          status: cause?.status,
          error: result.message,
        });
      } else
        onAdd({
          ...emptyTelemetry,
          ...telemetry,
          message: result.deReturnAPI,
          status: 200,
          ...customFields,
        });
    },
    [onAdd]
  );

  const onOpenTelemetry = useCallback(() => {
    if (telemetryWindow?.closed === false) telemetryWindow.focus();
    else
      telemetryWindow = windowOpenSessionCleaner(
        externalPathEnum.telemetry,
        "Telemetry"
      );

    return () => telemetryWindow?.close();
  }, []);

  return (
    <TelemetryContext.Provider
      value={{
        telemetries,
        loading,
        updateStorage,
        addTelemetry,
        onOpenTelemetry,
      }}
    >
      {children}
    </TelemetryContext.Provider>
  );
};

export const useTelemetry = () => useContext(TelemetryContext);
