// Models

import { BaseReponseType } from "models";

export enum PasswordStatusEnum {
  NotExpired = 0,
  Expired = 1,
  AskChangeAlmostExpiredPassword = 2,
}

export type MyUserModel = {
  idPermission: number;
  idRole: number;
  NmRole: string;
  idProject: number;
  deProject: string;
  mustFilterStatusByCType: boolean;
  mustLoginPaused: boolean;
  idUser: number;
  nmUser: string;
  nmLoginTelephony: string;
  deUrlWebPhone: string;
  deUrlDialerAPI: string;
  mustUseIFrameWebPhone: boolean;
  hasAccessAttend: boolean;
  hasAccessDashboard: boolean;
  hasAccessAudit: boolean;
  hasAccessReport: boolean;
  hasAccessRegister: boolean;
  hasAccessAdmin: boolean;
  hasOrderModule: boolean;
  hasQuestionnaireModule: boolean;
  hasTicketModule: boolean;
  hasVideoModule: boolean;
  hasNewRecord: boolean;
  hasConferenceAfterApproach: boolean;
  hasDialerAPI: boolean;
  hasEditTicket: boolean;
  hasDialerQuery: boolean;
  hasHSMFromVoice: boolean;
  hasMakeCallFromWhatsApp: boolean;
  nmsColumnsTicket: string;
  mustHideTimeline: boolean;
  mustBlockTransferOmni: boolean;
  mustBlockChangePassword: boolean;
  mustHideQueryMailing: boolean;
  mustHideQueryOmni: boolean;
  mustHideInsertMailing: boolean;
  allowRedialCurrPhoneApproa: boolean;
  qtHourTimeZoneIdProject: number;
};

export type UserBatchModel = {
  idReturnAPI: number;
  deReturnAPI: string;
  nuRegistrarion: string;
  nmUser: string;
  deLogin: string;
  nmLoginTelephony: string;
  deEmail: string;
  nuDDD: string;
  nuPhone: string;
  dtBirth: string;
  nuRegistrationParent: string;
};

export type UserModel = {
  IdUser: number;
  NmUser: string;
  IsActive: boolean;
  DtBirth: string;
  DtLastInactive: string;
  DtRegister: string;
  NuRegistration: string;
  CodeUser: string;
  DeLogin: string;
  NmLoginTelephony: string;
  IdProject: number;
  DeProject: string;
  IdUserParent: number;
  NmUserParent: string;
  IdRole: number;
  NmRole: string;
  DeEmail: string;
  NuDDD: string;
  NuPhone: string;
  QtMaxOmnisActives: string;
  DtPwdChanged: string;
};

export type UserChannelModel = {
  IdAttendGroup: number;
  NmAttendGroup: string;
  CodeAttendGroup: string;
  NmAttendGroupCodeAttendGroup: string;
};

// Requests

export type ChangePasswordRequest = {
  pDeToken: string;
  pDePassword: string;
  pDeNewPassword: string;
  pDeConfirmPassword: string;
};

export type GetMeRequest = {
  pIdProject: number;
};

export type GetNoteRequest = {
  pDeToken: string;
};

export type GetUserChannelsRequest = {
  pIdProject: number;
  pIdUser: number;
};

export type GetUsersRequest = {
  pIdProject: number[];
  pNmUser?: string;
  pIdUserParent?: number;
  pIsActive?: boolean;
  pNuRegistration?: string;
  pCodeUser?: string;
};

export type ManageNoteRequest = {
  pDeToken: string;
  pDeNotepad: string;
};

export type ManageUserRequest = {
  pIdUser: number;
  pNmUser: string;
  pDtBirth: string;
  pNuRegistration: string;
  pDeLogin: string;
  pNmLoginTelephony?: string;
  pCodeUser: string;
  pDeEmail: string;
  pIdRole: number;
  pIdProject: number;
  pIdUserParent: number;
  pIsActive: boolean;
  pNuDDD: string;
  pNuPhone: string;
  pQtMaxOmnisActives: string;
  pMustResetPassword: boolean;
  pDtPwdChanged?: string;
};

export type ManageUserBatchRequest = {
  pIdProject: number;
};

export type ManageUserProjectRequest = {
  pIdUser: number;
  pIdProject: number[];
};

export type SignInRequest = {
  pDeLogin: string;
  pDePasswordHash: string;
  pDeUrl: string;
};

// Responses

export type ChangePasswordResponse = BaseReponseType;

export type GetMeResponse = BaseReponseType & MyUserModel;

export type GetNoteResponse = BaseReponseType & {
  deNotepad: string;
};

export type GetUserChannelsResponse = BaseReponseType & {
  attendGroupUserist: UserChannelModel[];
};

export type GetUsersResponse = BaseReponseType & {
  userList: UserModel[];
};

export type ManageNoteResponse = BaseReponseType;

export type ManageUserResponse = BaseReponseType;

export type ManageUserBatchResponse = BaseReponseType & {
  managerUserBatchList: UserBatchModel[];
};

export type ManageUserProjectResponse = BaseReponseType;

export type SignInResponse = Required<BaseReponseType> & {
  deToken: string;
  passwordExpiredStatus: PasswordStatusEnum;
};

export type SignOutResponse = Required<BaseReponseType>;
