import { Errors } from "classes";
import {
  BaseProviderType,
  PermissionProviderType,
  UserParentOptModel,
} from "models";
import { useProfile, useToast } from "providers";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import * as ListService from "services/list";
import { aytyFormatError, sortBy } from "utils";

type UserListContextType = {
  loading: boolean;
  userParentList?: UserParentOptModel[];
  userParentOpts?: UserParentOptModel[];
  getUserParentOpts: () => Promise<void>;
};

const UserListContext = createContext({} as UserListContextType);

const Provider = ({ children }: BaseProviderType) => {
  const [loading, setLoading] = useState(true);
  const [userParentList, setUserParentList] = useState<UserParentOptModel[]>();
  const { user, pIdProject, projectOpts } = useProfile();
  const { error, warning } = useToast();
  const { t } = useTranslation();

  const errorsResolver = useMemo(
    () => new Errors({ error, warning }, t),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const userParentOpts = useMemo(
    () => userParentList?.filter((u) => u.IdProject === pIdProject),
    [userParentList, pIdProject]
  );

  const getUserParentOpts = useCallback(async () => {
    if (projectOpts?.length && user)
      await ListService.getUserParentOpts({
        pIdProject: projectOpts.map((p) => p.IdProject),
      })
        .then(({ data }) => {
          if (data.idReturnAPI > 0) {
            const current = data.userParentList?.filter((u) => u.IsActive);
            setUserParentList(sortBy(current, "NmUserParent"));
          } else warning({ description: t(aytyFormatError(data)) });
        })
        .catch(errorsResolver.defaultError);

    setLoading(false);
  }, [projectOpts, user, errorsResolver, warning, t]);

  useEffect(() => {
    getUserParentOpts();
  }, [getUserParentOpts]);

  return (
    <UserListContext.Provider
      value={{
        loading,
        userParentList,
        userParentOpts,
        getUserParentOpts,
      }}
    >
      {children}
    </UserListContext.Provider>
  );
};

export const UserListProvider = ({
  hasPermission = true,
  ...args
}: PermissionProviderType) => {
  if (!hasPermission) return <>{args.children}</>;

  return <Provider {...args} />;
};

export const useUserList = () => useContext(UserListContext);
