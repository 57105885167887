import { AxiosRequestConfig } from "axios";
import { formDataConfig } from "consts";
import {
  ChangePasswordRequest,
  ChangePasswordResponse,
  GetMeRequest,
  GetMeResponse,
  GetNoteRequest,
  GetNoteResponse,
  GetUserChannelsRequest,
  GetUserChannelsResponse,
  GetUsersRequest,
  GetUsersResponse,
  ManageNoteRequest,
  ManageNoteResponse,
  ManageUserBatchRequest,
  ManageUserBatchResponse,
  ManageUserProjectRequest,
  ManageUserProjectResponse,
  ManageUserRequest,
  ManageUserResponse,
  SignInRequest,
  SignInResponse,
  SignOutResponse,
} from "models";
import service from "services";

// Authentication
export const changePassword = async (
  params: ChangePasswordRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<ChangePasswordResponse>("User/ChangePassword", {
    ...config,
    params,
  });

export const signIn = async (
  params: SignInRequest,
  config?: AxiosRequestConfig
) => await service.get<SignInResponse>("User/Login", { ...config, params });

export const signOut = async (config?: AxiosRequestConfig) =>
  await service.get<SignOutResponse>("User/Logout", config);

// Current user
export const getMe = async (
  params: GetMeRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetMeResponse>("User/GetCurrentUserData", {
    ...config,
    params,
  });

export const getNote = async (
  params: GetNoteRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetNoteResponse>("User/GetUserNote", { ...config, params });

export const manageNote = async (
  params: ManageNoteRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<ManageNoteResponse>("User/ManagerUserNote", {
    ...config,
    params,
  });

// Manage user
export const getUserChannels = async (
  params: GetUserChannelsRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetUserChannelsResponse>("User/GetAttendGroupUserList", {
    ...config,
    params,
  });

export const getUsers = async (
  params: GetUsersRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetUsersResponse>("User/QueryUser", {
    ...config,
    params,
    paramsSerializer: { indexes: null },
  });

export const manageUser = async (
  params: ManageUserRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<ManageUserResponse>("User/ManagerUser", {
    ...config,
    params,
  });

export const manageUserBatch = async (
  data: FormData,
  params: ManageUserBatchRequest,
  config?: AxiosRequestConfig
) =>
  await service.post<ManageUserBatchResponse>("User/ManagerUserBatch", data, {
    ...config,
    ...formDataConfig,
    params,
  });

export const manageUserProject = async (
  params: ManageUserProjectRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<ManageUserProjectResponse>("User/ManagerUserProject", {
    ...config,
    params,
    paramsSerializer: { indexes: null },
  });
