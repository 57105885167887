import { oneSecond, ss, statusKey } from "consts";
import { intervalToDuration } from "date-fns";
import { useMap } from "hooks";
import SelectInterval from "modals/selectInterval";
import {
  BaseProviderType,
  IntervalModel,
  IntervalOptModel,
  PermissionProviderType,
} from "models";
import { useProfile, useToast } from "providers";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Errors } from "classes";
import * as IntervalService from "services/interval";
import { aytyFormatError, sortBy } from "utils";

type UndefinedMap = Map<number, IntervalModel> | undefined;

type IntervalContextType = {
  loading: boolean;
  selectedItem?: IntervalOptModel;
  inPause?: boolean;
  status?: number;
  firstActiveItem?: number;
  formatedDuration?: string;
  intervals?: IntervalModel[];
  activeIntervals?: IntervalModel[];
  intervalOpts?: IntervalOptModel[];
  getIntervals: () => Promise<void>;
  addInterval: (key: number, value: IntervalModel) => Promise<UndefinedMap>;
  editInterval: (key: number, value: IntervalModel) => Promise<UndefinedMap>;
  removeInterval: (key: number) => Promise<UndefinedMap>;
  handleChangeStatus: (statusId?: number) => Promise<void>;
  handleOpenModal: () => void;
};

const IntervalContext = createContext({} as IntervalContextType);

const Provider = ({ children }: BaseProviderType) => {
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [status, setStatus] = useState<number>();
  const [pausedAt, setPausedAt] = useState<number>();
  const [duration, setDuration] = useState<Duration>();
  const [list, { set, add, edit, remove }] = useMap<number, IntervalModel>();
  const [intervalOpts, setIntervalOpts] = useState<IntervalOptModel[]>();
  const { pIdProject, user } = useProfile();
  const { error, warning } = useToast();
  const { t } = useTranslation();

  const errorsResolver = useMemo(
    () => new Errors({ error, warning }, t),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const intervals = useMemo(() => {
    if (list) return Array.from(list.values());
  }, [list]);

  const activeIntervals = useMemo(
    () => intervals?.filter((c) => c.IsActive),
    [intervals]
  );

  const selectedItem = useMemo(() => {
    if (status) return intervalOpts?.find((i) => i.IdEventType === status);
  }, [intervalOpts, status]);

  const inPause = useMemo(() => selectedItem?.IsPause, [selectedItem]);

  const firstActiveItem = useMemo(
    () => intervalOpts?.find((i) => !i.IsPause)?.IdEventType,
    [intervalOpts]
  );

  const formatedDuration = useMemo(() => {
    if (duration) {
      const hours = duration.hours?.toString().padStart(2, "0");
      const minutes = duration.minutes?.toString().padStart(2, "0");
      const seconds = duration.seconds?.toString().padStart(2, "0");
      return `${hours}:${minutes}:${seconds}`;
    }

    return `00:00:00`;
  }, [duration]);

  useEffect(() => {
    if (!!intervalOpts?.length) {
      const { status, at } = JSON.parse(ss.getItem(statusKey) ?? "{}");

      setStatus(status ?? intervalOpts?.find((i) => !i.IsPause)?.IdEventType);
      setPausedAt(at);
    }
  }, [intervalOpts]);

  useEffect(() => {
    if (!inPause) setDuration(undefined);
  }, [inPause]);

  const getIntervals = useCallback(async () => {
    if (pIdProject)
      await Promise.all([
        user?.hasAccessAdmin &&
          IntervalService.getIntervals({ pIdProject }).then(({ data }) => {
            if (data.idReturnAPI > 0) {
              const sortList = sortBy(data.eventTypePauseList, "DeEventType");
              set(new Map(sortList.map((i) => [i.IdEventType, i])));
            } else warning({ description: t(aytyFormatError(data)) });
          }),
        IntervalService.getIntervalOpts({ pIdProject }).then(({ data }) => {
          if (data.idReturnAPI > 0)
            setIntervalOpts(sortBy(data.eventTypeList, "DeEventType"));
          else warning({ description: t(aytyFormatError(data)) });
        }),
      ]).catch(errorsResolver.defaultError);
  }, [user, pIdProject, errorsResolver, set, warning, t]);

  useEffect(() => {
    getIntervals();
  }, [getIntervals]);

  const handleChangeStatus = useCallback(
    async (statusId?: number) => {
      setLoading(true);
      const status = statusId ?? firstActiveItem;

      if (status)
        await IntervalService.selectInterval({
          pIdEventType: status,
        })
          .then(({ data }) => {
            if (data.idReturnAPI > 0) {
              setStatus(status);

              const paused = !!intervalOpts?.find(
                (i) => i.IdEventType === status
              )?.IsPause;
              const at = paused ? Date.now() : undefined;

              setPausedAt(at);
              ss.setItem(statusKey, JSON.stringify({ status, at }));
            } else warning({ description: t(aytyFormatError(data)) });
          })
          .catch(errorsResolver.defaultError);
      setLoading(false);
    },
    [intervalOpts, firstActiveItem, errorsResolver, warning, t]
  );

  const getDuration = useCallback((timer: number) => {
    setDuration(
      intervalToDuration({
        start: new Date(timer),
        end: new Date(),
      })
    );
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (pausedAt) getDuration(pausedAt);
    }, oneSecond);

    return () => clearInterval(interval);
  }, [pausedAt, getDuration]);

  const handleOpenModal = useCallback(
    () => !inPause && setModal(true),
    [inPause]
  );

  return (
    <IntervalContext.Provider
      value={{
        loading,
        selectedItem,
        inPause,
        status,
        firstActiveItem,
        formatedDuration,
        intervals,
        activeIntervals,
        intervalOpts,
        getIntervals,
        addInterval: add,
        editInterval: edit,
        removeInterval: remove,
        handleChangeStatus,
        handleOpenModal,
      }}
    >
      {children}
      <SelectInterval open={modal} onClose={() => setModal(false)} />
    </IntervalContext.Provider>
  );
};

export const IntervalProvider = ({
  hasPermission = true,
  ...args
}: PermissionProviderType) => {
  if (!hasPermission) return <>{args.children}</>;

  return <Provider {...args} />;
};

export const useInterval = () => useContext(IntervalContext);
