import { toLocaleFormat } from "consts";
import { format } from "date-fns";
import {
  CampaignModel,
  CampaignTypeModel,
  ChannelModel,
  ControlTypeIDEnum,
  CustomTableModel,
  FAQModel,
  FieldModel,
  FormModel,
  HSMBatchModel,
  HSMTemplateModel,
  IntervalModel,
  MessageItemModel,
  OrderModel,
  ProductModel,
  ProjectModel,
  QueueModel,
  SearchOmniModel,
  StatusByCampaignModel,
  StatusGroupModel,
  StatusModel,
  TicketModel,
  UserModel,
} from "models";

export class CampaignExportClass {
  IdCampaign: number;
  NmCampaign: string;
  IsActive: boolean;
  IdAttendGroupOmniWhatsapp: number;
  NmAttendGroupOmniWhatsapp: string;

  constructor(c: CampaignModel) {
    this.IdCampaign = c.IdCampaign;
    this.NmCampaign = c.NmCampaign;
    this.IsActive = c.IsActive;
    this.IdAttendGroupOmniWhatsapp = c.IdAttendGroupOmniWhatsapp;
    this.NmAttendGroupOmniWhatsapp = c.NmAttendGroupOmniWhatsapp;
  }
}

export class CampaignTypeExportClass {
  IdCampaignType: number;
  NmCampaignType: string;
  IsActive: boolean;
  IdCrmConfiguration: number;
  NmCrmConfiguration: string;

  constructor(c: CampaignTypeModel) {
    this.IdCampaignType = c.IdCampaignType;
    this.NmCampaignType = c.NmCampaignType;
    this.IsActive = c.IsActive;
    this.IdCrmConfiguration = c.IdCrmConfiguration;
    this.NmCrmConfiguration = c.NmCrmConfiguration;
  }
}

export class ChannelExportClass {
  IdOmniSource: number;
  NmOmniSource: string;
  DeMessageFinalizeAttend: string;
  DeMsgOmniCongrats: string;
  IdSubChannelType: number;
  IsActive: boolean;
  NuPhoneWhatsapp: string;
  CodeSocialMediaSource: string;
  DeWarningMessageAutoFinishOmni: string;
  QtSecWarningMessageAutoFinishOmni: number;
  DeMessageAutoFinishOmni: string;
  QtSecNoMessageAutoFinishOmni: number;
  HasFinalizeMetaWindow: boolean;
  DeFinalizeMetaWindow: string;
  IdCampaignType: number;
  NmCampaignType: string;
  IdBotCallbackResponses: string;
  QtMaxOmnisActives: string;

  constructor(c: ChannelModel) {
    this.IdOmniSource = c.IdOmniSource;
    this.DeMessageFinalizeAttend = c.DeMessageFinalizeAttend;
    this.DeMsgOmniCongrats = c.DeMsgOmniCongrats;
    this.IdSubChannelType = c.IdSubChannelType;
    this.IsActive = c.IsActive;
    this.NmOmniSource = c.NmOmniSource;
    this.NuPhoneWhatsapp = c.NuPhoneWhatsapp;
    this.CodeSocialMediaSource = c.CodeSocialMediaSource;
    this.DeWarningMessageAutoFinishOmni = c.DeWarningMessageAutoFinishOmni;
    this.QtSecWarningMessageAutoFinishOmni =
      c.QtSecWarningMessageAutoFinishOmni;
    this.DeMessageAutoFinishOmni = c.DeMessageAutoFinishOmni;
    this.QtSecNoMessageAutoFinishOmni = c.QtSecNoMessageAutoFinishOmni;
    this.HasFinalizeMetaWindow = c.HasFinalizeMetaWindow;
    this.DeFinalizeMetaWindow = c.DeFinalizeMetaWindow;
    this.IdCampaignType = c.IdCampaignType;
    this.NmCampaignType = c.NmCampaignType;
    this.IdBotCallbackResponses = c.IdBotCallbackResponses;
    this.QtMaxOmnisActives = c.QtMaxOmnisActives;
  }
}

export class CustomExportClass {
  IdCustom: number;
  IdProject: number;
  NmCustom: string;
  IdParent?: number;
  IsActive: boolean;

  constructor(c: CustomTableModel) {
    this.IdCustom = c.IdCustom;
    this.IdProject = c.IdProject;
    this.NmCustom = c.NmCustom;
    this.IdParent = c.IdParent;
    this.IsActive = c.IsActive;
  }
}

export class FAQExportClass {
  IdOmniFAQ: number;
  DeQuestion: string;
  DeAnswer: string;
  IsActive: boolean;

  constructor(f: FAQModel) {
    this.IdOmniFAQ = f.IdOmniFAQ;
    this.DeQuestion = f.DeQuestion;
    this.DeAnswer = f.DeAnswer;
    this.IsActive = f.IsActive;
  }
}

export class FieldExportClass {
  IdBind: number;
  IdControlType: ControlTypeIDEnum;
  NmControlType: string;
  DeCaption: string;
  IdDbType: number;
  NmDbType: string;
  NmColumn: string;
  NmForeignKeyTable: string;
  NmForeignKeyOrderBy: string;
  NmForeignKeyDataTextField: string;
  NmForeignKeyDataValueField: string;
  NuOrder: number;
  IsActive: boolean;
  NuControlWidth: number;
  ControlColorRGB: string;
  RegexToValidate: string;
  DeMessageValidateByRegex: string;
  IdContext: number;
  DeContext: string;
  Mask: string;
  MaxLength: number;
  MustBeFilled: boolean;
  MustFilterByIdProject: boolean;
  MustBlockEditData: boolean;
  IdCrmConfiguration: number;

  constructor(f: FieldModel) {
    this.IdBind = f.IdBind;
    this.IdControlType = f.IdControlType;
    this.NmControlType = f.NmControlType;
    this.DeCaption = f.DeCaption;
    this.IdDbType = f.IdDbType;
    this.NmDbType = f.NmDbType;
    this.NmColumn = f.NmColumn;
    this.NmForeignKeyTable = f.NmForeignKeyTable;
    this.NmForeignKeyOrderBy = f.NmForeignKeyOrderBy;
    this.NmForeignKeyDataTextField = f.NmForeignKeyDataTextField;
    this.NmForeignKeyDataValueField = f.NmForeignKeyDataValueField;
    this.NuOrder = f.NuOrder;
    this.IsActive = f.IsActive;
    this.NuControlWidth = f.NuControlWidth;
    this.ControlColorRGB = f.ControlColorRGB;
    this.RegexToValidate = f.RegexToValidate;
    this.DeMessageValidateByRegex = f.DeMessageValidateByRegex;
    this.IdContext = f.IdContext;
    this.DeContext = f.DeContext;
    this.Mask = f.Mask;
    this.MaxLength = f.MaxLength;
    this.MustBeFilled = f.MustBeFilled;
    this.MustFilterByIdProject = f.MustFilterByIdProject;
    this.MustBlockEditData = f.MustBlockEditData;
    this.IdCrmConfiguration = f.IdCrmConfiguration;
  }
}

export class FormExportClass {
  IdCrmConfiguration: number;
  NmCrmConfiguration: string;
  IsActive: boolean;

  constructor(f: FormModel) {
    this.IdCrmConfiguration = f.IdCrmConfiguration;
    this.NmCrmConfiguration = f.NmCrmConfiguration;
    this.IsActive = f.IsActive;
  }
}

export class HSMBatchExportClass {
  IdHsmBatch: number;
  NmHsmBatch: string;
  DeProject: string;
  IsActive: boolean;
  DtRegister: string;
  NmFile: string;
  IdMessageOutboundTemplate: number;
  NmMessageOutboundTemplate: string;
  DtSchedule: string;
  WasProcessed: boolean;
  DeResult: string;
  HasError: boolean;
  QtRegister: number;
  QtSent: number;
  QtDelivered: number;
  QtRead: number;
  QtAnswered: number;
  QtNotAnswered: number;
  QtFailed: number;
  QtValidPhone: number;
  QtInvalidPhone: number;
  QtUndelivered: number;
  QtInternalFailed: number;
  QtBspFailed: number;

  constructor(h: HSMBatchModel) {
    this.IdHsmBatch = h.IdHsmBatch;
    this.NmHsmBatch = h.NmHsmBatch;
    this.DeProject = h.DeProject;
    this.IsActive = h.IsActive;
    this.DtRegister = h.DtRegister;
    this.NmFile = h.NmFile;
    this.IdMessageOutboundTemplate = h.IdMessageOutboundTemplate;
    this.NmMessageOutboundTemplate = h.NmMessageOutboundTemplate;
    this.DtSchedule = h.DtSchedule;
    this.WasProcessed = h.WasProcessed;
    this.DeResult = h.DeResult;
    this.HasError = h.HasError;
    this.QtRegister = h.QtRegister;
    this.QtSent = h.QtSent;
    this.QtDelivered = h.QtDelivered;
    this.QtRead = h.QtRead;
    this.QtAnswered = h.QtAnswered;
    this.QtNotAnswered = h.QtNotAnswered;
    this.QtFailed = h.QtFailed;
    this.QtValidPhone = h.QtValidPhone;
    this.QtInvalidPhone = h.QtInvalidPhone;
    this.QtUndelivered = h.QtUndelivered;
    this.QtInternalFailed = h.QtInternalFailed;
    this.QtBspFailed = h.QtBspFailed;
  }
}

export class HSMTemplateExportClass {
  IdMessageOutboundTemplate: number;
  IdOmniSource: number;
  NmOmniSource: string;
  NmMessageOutboundTemplate: string;
  DeMessageOutboundTemplate: string;
  CodeNamespace: string;
  DeVariables: string;
  CodeSocialMediaSource: string;
  IsActive: boolean;
  DeUrlCallbackResponses: string;

  constructor(h: HSMTemplateModel) {
    this.IdMessageOutboundTemplate = h.IdMessageOutboundTemplate;
    this.IdOmniSource = h.IdOmniSource;
    this.NmOmniSource = h.NmOmniSource;
    this.NmMessageOutboundTemplate = h.NmMessageOutboundTemplate;
    this.DeMessageOutboundTemplate = h.DeMessageOutboundTemplate;
    this.CodeNamespace = h.CodeNamespace;
    this.DeVariables = h.DeVariables;
    this.CodeSocialMediaSource = h.CodeSocialMediaSource;
    this.IsActive = h.IsActive;
    this.DeUrlCallbackResponses = h.DeUrlCallbackResponses;
  }
}

export class IntervalExportClass {
  IdEventType: number;
  DeEventType: string;
  IsActive: boolean;
  CodeStatus: string;

  constructor(i: IntervalModel) {
    this.IdEventType = i.IdEventType;
    this.DeEventType = i.DeEventType;
    this.IsActive = i.IsActive;
    this.CodeStatus = i.CodeStatus;
  }
}

export class MessageItemExportClass {
  IdOmni: number;
  NmMailingFrom: string;
  NmMailingTo: string;
  DtRegister: string;
  DeOmniMessage: string;

  constructor(m: MessageItemModel) {
    this.IdOmni = m.IdOmni;
    this.NmMailingFrom = m.NmMailingFrom;
    this.NmMailingTo = m.NmMailingTo;
    this.DtRegister = format(new Date(m.DtRegister), toLocaleFormat);
    this.DeOmniMessage = m.DeOmniMessage;
  }
}

export class OrderExportClass {
  IdOrder: number;
  NmOrderState: string;
  IdMailing: number;
  NmMailing: string;
  DtRegister: string;
  NmUserRegister: string;
  DtLastRegister: string;
  NmUserLastRegister: string;
  IdProduct: number;
  NmProduct: string;
  DeObs: string;
  IdOmni: number;

  constructor(o: OrderModel) {
    this.IdOrder = o.IdOrder;
    this.NmOrderState = o.NmOrderState;
    this.IdMailing = o.IdMailing;
    this.NmMailing = o.NmMailing;
    this.DtRegister = o.DtRegister;
    this.NmUserRegister = o.NmUserRegister;
    this.DtLastRegister = o.DtLastRegister;
    this.NmUserLastRegister = o.NmUserLastRegister;
    this.IdProduct = o.IdProduct;
    this.NmProduct = o.NmProduct;
    this.DeObs = o.DeObs;
    this.IdOmni = o.IdOmni;
  }
}

export class ProductExportClass {
  IdProduct: number;
  NmProduct: string;
  VlProduct?: number;
  IsActive: boolean;

  constructor(p: ProductModel) {
    this.IdProduct = p.IdProduct;
    this.NmProduct = p.NmProduct;
    this.VlProduct = p.VlProduct;
    this.IsActive = p.IsActive;
  }
}

export class ProjectExportClass {
  IdProject: number;
  DeProject: string;
  IsActive: boolean;
  DeUrlWebPhone: string;
  DeUrlDialerApi: string;
  MustFilterStatusByCampaignType: boolean;

  constructor(p: ProjectModel) {
    this.IdProject = p.IdProject;
    this.DeProject = p.DeProject;
    this.IsActive = p.IsActive;
    this.DeUrlWebPhone = p.DeUrlWebPhone;
    this.DeUrlDialerApi = p.DeUrlDialerApi;
    this.MustFilterStatusByCampaignType = p.MustFilterStatusByCampaignType;
  }
}

export class QueueExportClass {
  IdAttendGroup: number;
  NmAttendGroup: string;
  DeProject: string;
  IsActive: boolean;
  NmChannelType: string;
  IdChannelType: number;
  NmOmniSource: string;
  IdOmniSource: number;
  HrDsStart1: string;
  HrDsFinish1: string;
  HrDsStartSaturday1: string;
  HrDsFinishSaturday1: string;
  HrDsStartSunday1: string;
  HrDsFinishSunday1: string;
  QtMaxOmnisActives: string;

  constructor(q: QueueModel) {
    this.IdAttendGroup = q.IdAttendGroup;
    this.NmAttendGroup = q.NmAttendGroup;
    this.DeProject = q.DeProject;
    this.IsActive = q.IsActive;
    this.NmChannelType = q.NmChannelType;
    this.IdChannelType = q.IdChannelType;
    this.NmOmniSource = q.NmOmniSource;
    this.IdOmniSource = q.IdOmniSource;
    this.HrDsStart1 = q.HrDsStart1;
    this.HrDsFinish1 = q.HrDsFinish1;
    this.HrDsStartSaturday1 = q.HrDsStartSaturday1;
    this.HrDsFinishSaturday1 = q.HrDsFinishSaturday1;
    this.HrDsStartSunday1 = q.HrDsStartSunday1;
    this.HrDsFinishSunday1 = q.HrDsFinishSunday1;
    this.QtMaxOmnisActives = q.QtMaxOmnisActives;
  }
}

export class SearchOmnigExportClass {
  IdOmni: number;
  IdOmniSource: number;
  IdMailing: number;
  NmMailing: string;
  NuRegistration: string;
  IdStatus: number;
  NmStatus: string;
  DtStart: string;
  NmUser: string;
  NmOmniFolder: string;
  NuPhoneWhatsapp: string;
  NuDdd: string;
  NuPhone: string;
  NmSubChannelType: string;
  NmOmniSource: string;

  constructor(s: SearchOmniModel) {
    this.IdOmni = s.IdOmni;
    this.IdOmniSource = s.IdOmniSource;
    this.IdMailing = s.IdMailing;
    this.NmMailing = s.NmMailing;
    this.NuRegistration = s.NuRegistration;
    this.IdStatus = s.IdStatus;
    this.NmStatus = s.NmStatus;
    this.DtStart = s.DtStart;
    this.NmUser = s.NmUser;
    this.NmOmniFolder = s.NmOmniFolder;
    this.NuPhoneWhatsapp = s.NuPhoneWhatsapp;
    this.NuDdd = s.NuDdd1;
    this.NuPhone = s.NuPhone1;
    this.NmSubChannelType = s.NmSubChannelType;
    this.NmOmniSource = s.NmOmniSource;
  }
}

export class StatusByCampaignExportClass {
  IdStatus: number;
  NmStatus: string;
  IdStatusGroup: number;
  NmStatusGroup: string;
  IdCampaignType?: number;
  IsAvailableCampaignType: boolean;

  constructor(s: StatusByCampaignModel) {
    this.IdStatus = s.IdStatus;
    this.NmStatus = s.NmStatus;
    this.IdStatusGroup = s.IdStatusGroup;
    this.NmStatusGroup = s.NmStatusGroup;
    this.IdCampaignType = s.IdCampaignType;
    this.IsAvailableCampaignType = s.IsAvailableCampaignType;
  }
}

export class StatusGroupExportClass {
  IdStatusGroup: number;
  NmStatusGroup: string;
  IsActive: boolean;

  constructor(s: StatusGroupModel) {
    this.IdStatusGroup = s.IdStatusGroup;
    this.NmStatusGroup = s.NmStatusGroup;
    this.IsActive = s.IsActive;
  }
}

export class StatusExportClass {
  IdStatus: number;
  NmStatus: string;
  IsActive: boolean;
  IdStatusGroup: number;
  NmStatusGroup: string;
  CodeStatusClient: string;
  CodeStatusCrmToPredictive: string;
  IdStatusTypeDs: number;
  NmStatusTypeDs: string;
  IdProject: number;
  IsAvailableVoice: boolean;
  IsAvailableChat: boolean;
  IsAvailableApproach: boolean;
  IsAvailableRedial: boolean;
  IsAvailableSchedule: boolean;
  IsApproach: boolean;
  IsOrder: boolean;
  MustValidateBindListFinishOmni: boolean;

  constructor(s: StatusModel) {
    this.IdStatus = s.IdStatus;
    this.NmStatus = s.NmStatus;
    this.IsActive = s.IsActive;
    this.IdStatusGroup = s.IdStatusGroup;
    this.NmStatusGroup = s.NmStatusGroup;
    this.CodeStatusClient = s.CodeStatusClient;
    this.CodeStatusCrmToPredictive = s.CodeStatusCrmToPredictive;
    this.IdStatusTypeDs = s.IdStatusTypeDs;
    this.NmStatusTypeDs = s.NmStatusTypeDs;
    this.IdProject = s.IdProject;
    this.IsAvailableVoice = s.IsAvailableVoice;
    this.IsAvailableChat = s.IsAvailableChat;
    this.IsAvailableApproach = s.IsAvailableApproach;
    this.IsAvailableRedial = s.IsAvailableRedial;
    this.IsAvailableSchedule = s.IsAvailableSchedule;
    this.IsApproach = s.IsApproach;
    this.IsOrder = s.IsOrder;
    this.MustValidateBindListFinishOmni = s.MustValidateBindListFinishOmni;
  }
}

export class TicketExportClass {
  IdTicket: number;
  IdTicketState: number;
  NmTicketState: string;
  IdTicketType: number;
  NmTicketType: string;
  IdLastTicketEvent: number;
  NuGuidDts: number;
  IdMailing: number;
  NmMailing: string;
  DtRegister: string;
  IdUserRegister: number;
  NmUserRegister: string;
  DtLastRegister: string;
  IdUserLastRegister: number;
  NmUserLastRegister: string;
  IdTicketCell: number;
  NmTicketCell: string;
  DeObs: string;
  CodeProtocolIssue: string;
  CodeProtocol: string;
  IdTicketReason: number;
  NmTicketReason: string;
  IdTicketSubReason: number;
  NmTicketSubReason: string;
  DeDescription: string;
  DeEmailRequest1: string;
  NuDddRequest1: string;
  NuPhoneRequest1: string;
  NmRequest: string;

  constructor(t: TicketModel) {
    this.IdTicket = t.IdTicket;
    this.IdTicketState = t.IdTicketState;
    this.NmTicketState = t.NmTicketState;
    this.IdTicketType = t.IdTicketType;
    this.NmTicketType = t.NmTicketType;
    this.IdLastTicketEvent = t.IdLastTicketEvent;
    this.NuGuidDts = t.NuGuidDts;
    this.IdMailing = t.IdMailing;
    this.NmMailing = t.NmMailing;
    this.DtRegister = t.DtRegister;
    this.IdUserRegister = t.IdUserRegister;
    this.NmUserRegister = t.NmUserRegister;
    this.DtLastRegister = t.DtLastRegister;
    this.IdUserLastRegister = t.IdUserLastRegister;
    this.NmUserLastRegister = t.NmUserLastRegister;
    this.IdTicketCell = t.IdTicketCell;
    this.NmTicketCell = t.NmTicketCell;
    this.DeObs = t.DeObs;
    this.CodeProtocolIssue = t.CodeProtocolIssue;
    this.CodeProtocol = t.CodeProtocol;
    this.IdTicketReason = t.IdTicketReason;
    this.NmTicketReason = t.NmTicketReason;
    this.IdTicketSubReason = t.IdTicketSubReason;
    this.NmTicketSubReason = t.NmTicketSubReason;
    this.DeDescription = t.DeDescription;
    this.DeEmailRequest1 = t.DeEmailRequest1;
    this.NuDddRequest1 = t.NuDddRequest1;
    this.NuPhoneRequest1 = t.NuPhoneRequest1;
    this.NmRequest = t.NmRequest;
  }
}

export class UserExportClass {
  IdUser: number;
  NmUser: string;
  IsActive: boolean;
  DtBirth: string;
  DtLastInactive: string;
  DtRegister: string;
  NuRegistration: string;
  CodeUser: string;
  DeLogin: string;
  DeProject: string;
  IdUserParent: number;
  NmUserParent: string;
  IdRole: number;
  NmRole: string;
  DeEmail: string;
  NuDDD: string;
  NuPhone: string;
  DtPwdChanged: string;
  NmLoginTelephony: string;

  constructor(u: UserModel) {
    this.IdUser = u.IdUser;
    this.NmUser = u.NmUser;
    this.IsActive = u.IsActive;
    this.DtBirth = u.DtBirth;
    this.DtLastInactive = u.DtLastInactive;
    this.DtRegister = u.DtRegister;
    this.NuRegistration = u.NuRegistration;
    this.CodeUser = u.CodeUser;
    this.DeLogin = u.DeLogin;
    this.DeProject = u.DeProject;
    this.IdUserParent = u.IdUserParent;
    this.NmUserParent = u.NmUserParent;
    this.IdRole = u.IdRole;
    this.NmRole = u.NmRole;
    this.DeEmail = u.DeEmail;
    this.NuDDD = u.NuDDD;
    this.NuPhone = u.NuPhone;
    this.DtPwdChanged = u.DtPwdChanged;
    this.NmLoginTelephony = u.NmLoginTelephony;
  }
}
