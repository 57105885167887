import Button from "components/button";
import Form from "components/form";
import Modal, { ModalFooter, ModalHeader, ModalMain } from "components/modal";
import Select, { Option } from "components/select";
import { useEffect } from "react";
import useViewModel, { SelectIntervalParams } from "./viewModel";

const SelectInterval = (args: SelectIntervalParams) => {
  const { open, onClose } = args;
  const vm = useViewModel(args);
  const { loading, selected, list } = vm;
  const { t, onInit, handleSelect, onSubmit } = vm;

  useEffect(() => {
    onInit();
  }, [onInit]);

  return (
    <Modal show={open}>
      <Form>
        <ModalHeader title={t(`selectInterval.title`)} onClose={onClose} />
        <ModalMain $isNoOverflow>
          <Select
            $hasClear
            $fullWidth
            placeholder={t("select")}
            defaultValue={selected}
            onChange={handleSelect}
          >
            {list?.map(({ DeEventType, IdEventType }) => (
              <Option key={IdEventType} value={IdEventType}>
                {DeEventType}
              </Option>
            ))}
          </Select>
        </ModalMain>
        <ModalFooter>
          <Button $outline onClick={onClose}>
            {t("cancel")}
          </Button>
          <Button loading={loading} onClick={onSubmit}>
            {t("pause")}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default SelectInterval;
