import { Errors } from "classes";
import {
  ActionOptModel,
  BaseProviderType,
  FolderOptModel,
  PermissionProviderType,
  RoleOptModel,
  SubChannelTypeOptModel,
} from "models";
import {
  ProjectListProvider,
  useProfile,
  UserListProvider,
  useToast,
} from "providers";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import * as ListService from "services/list";
import { aytyFormatError, sortBy } from "utils";

type ListContextType = {
  loading: boolean;
  actionOpts?: ActionOptModel[];
  attendanceSubChannelTypeOpts?: SubChannelTypeOptModel[];
  folderOpts?: FolderOptModel[];
  roleOpts?: RoleOptModel[];
  subChannelTypeOpts?: SubChannelTypeOptModel[];
};

const ListContext = createContext({} as ListContextType);

const Provider = ({ children }: BaseProviderType) => {
  const [loading, setLoading] = useState(true);
  const [actionOpts, setActionOpts] = useState<ActionOptModel[]>();
  const [attendanceSubChannelTypeOpts, setAttendanceSubChannelTypeOpts] =
    useState<SubChannelTypeOptModel[]>();
  const [folderOpts, setFolderOpts] = useState<FolderOptModel[]>();
  const [roleOpts, setRoleOpts] = useState<RoleOptModel[]>();
  const [subChannelTypeOpts, setSubChannelTypeOpts] =
    useState<SubChannelTypeOptModel[]>();
  const { user } = useProfile();
  const { error, warning } = useToast();
  const { t } = useTranslation();

  const errorsResolver = useMemo(
    () => new Errors({ error, warning }, t),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getLists = useCallback(async () => {
    if (user?.hasAccessAttend)
      await Promise.all([
        ListService.getFolderOpts().then(({ data }) => {
          if (data.idReturnAPI > 0)
            setFolderOpts(sortBy(data.omniFolderList, "NmOmniFolder"));
          else warning({ description: t(aytyFormatError(data)) });
        }),
      ]).catch(errorsResolver.defaultError);

    if (user?.hasAccessRegister)
      await Promise.all([
        ListService.getActionOpts().then(({ data }) => {
          if (data.idReturnAPI > 0)
            setActionOpts(sortBy(data.actionList, "NmAction"));
          else warning({ description: t(aytyFormatError(data)) });
        }),
        ListService.getRoleOpts().then(({ data }) => {
          if (data.idReturnAPI > 0) {
            const current = data.roleList?.filter((r) => r.IsActive);
            setRoleOpts(sortBy(current, "NmRole"));
          } else warning({ description: t(aytyFormatError(data)) });
        }),
        ListService.getAttendanceSubChannelTypeOpts().then(({ data }) => {
          if (data.idReturnAPI > 0) {
            const current = data.channelTypeList?.filter((c) => c.IsActive);
            setAttendanceSubChannelTypeOpts(
              sortBy(current, "NmSubChannelType")
            );
          } else warning({ description: t(aytyFormatError(data)) });
        }),
      ]).catch(errorsResolver.defaultError);

    if (user?.hasAccessRegister || user?.hasAccessReport)
      await Promise.all([
        ListService.getSubChannelTypeOpts().then(({ data }) => {
          if (data.idReturnAPI > 0) {
            const current = data.channelTypeList?.filter((c) => c.IsActive);
            setSubChannelTypeOpts(sortBy(current, "NmSubChannelType"));
          } else warning({ description: t(aytyFormatError(data)) });
        }),
      ]).catch(errorsResolver.defaultError);

    setLoading(false);
  }, [user, errorsResolver, warning, t]);

  useEffect(() => {
    getLists();
  }, [getLists]);

  return (
    <ListContext.Provider
      value={{
        loading,
        actionOpts,
        attendanceSubChannelTypeOpts,
        folderOpts,
        roleOpts,
        subChannelTypeOpts,
      }}
    >
      <UserListProvider>
        <ProjectListProvider>{children}</ProjectListProvider>
      </UserListProvider>
    </ListContext.Provider>
  );
};

export const ListProvider = ({
  hasPermission = true,
  ...args
}: PermissionProviderType) => {
  if (!hasPermission) return <>{args.children}</>;

  return <Provider {...args} />;
};

export const useList = () => useContext(ListContext);
