import Layout from "components/layout";
import Loading from "components/loading";
import NotFound from "components/notFound";
import { useProfile } from "providers";
import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import RequireAuth from "./requireAuth";
import RequirePermission from "./requirePermission";

const AdministrativePage = lazy(() => import("pages/administrative"));
const AttendancePage = lazy(() => import("pages/attendance"));
const AuditPage = lazy(() => import("pages/audit"));
const AuthPage = lazy(() => import("pages/auth"));
const CompanyPage = lazy(() => import("pages/company"));
const DashboardPage = lazy(() => import("pages/dashboard"));
const ImportPage = lazy(() => import("pages/import"));
const NotepadPage = lazy(() => import("pages/notepad"));
const RegistrationPage = lazy(() => import("pages/registration"));
const ReportPage = lazy(() => import("pages/report"));
const TelemetryPage = lazy(() => import("pages/telemetry"));
const VideoCallPage = lazy(() => import("pages/videoCall"));
const WelcomePage = lazy(() => import("pages/welcome"));

const RoutesMap = () => {
  const { user } = useProfile();

  return (
    <Suspense fallback={<Loading $centered />}>
      <Routes>
        <Route path="authentication/*" element={<AuthPage />} />
        <Route path="videoCall/*" element={<VideoCallPage />} />
        <Route path="externalReport/*" element={<ReportPage />} />
        <Route path="notepad/*" element={<NotepadPage />} />
        <Route path="telemetry/*" element={<TelemetryPage />} />
        <Route path="/" element={<RequireAuth children={<Layout />} />}>
          <Route index element={<WelcomePage />} />
          <Route
            path="company/*"
            element={
              <RequirePermission
                children={<CompanyPage />}
                permission={user?.hasAccessAdmin}
              />
            }
          />
          <Route
            path="attendance/*"
            element={
              <RequirePermission
                children={<AttendancePage />}
                permission={user?.hasAccessAttend}
              />
            }
          />
          <Route
            path="dashboard/*"
            element={
              <RequirePermission
                children={<DashboardPage />}
                permission={user?.hasAccessDashboard}
              />
            }
          />
          <Route
            path="audit/*"
            element={
              <RequirePermission
                children={<AuditPage />}
                permission={user?.hasAccessAudit}
              />
            }
          />
          <Route
            path="report/*"
            element={
              <RequirePermission
                children={<ReportPage />}
                permission={user?.hasAccessReport}
              />
            }
          />
          <Route
            path="registration/*"
            element={
              <RequirePermission
                children={<RegistrationPage />}
                permission={user?.hasAccessRegister}
              />
            }
          />
          <Route
            path="administrative/*"
            element={
              <RequirePermission
                children={<AdministrativePage />}
                permission={user?.hasAccessAdmin}
              />
            }
          />
          <Route
            path="import/*"
            element={
              <RequirePermission
                children={<ImportPage />}
                permission={user?.hasAccessAdmin}
              />
            }
          />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default RoutesMap;
