import { fullToLocaleFormat } from "consts";
import { format } from "date-fns";
import { generateUID, milisecondsToTime } from "utils";

export class Telemetry {
  _uid: string;
  _startDate: number;
  path: string;

  constructor(path: string) {
    this._uid = generateUID();
    this._startDate = Date.now();
    this.path = path;
  }

  _getInterval = (endDate: number) => {
    const interval = endDate - this._startDate;
    return milisecondsToTime(interval);
  };

  finish = () => {
    const endDate = Date.now();
    const interval = this._getInterval(endDate);

    return {
      uid: this._uid,
      path: this.path,
      startDate: format(this._startDate, fullToLocaleFormat),
      endDate: format(endDate, fullToLocaleFormat),
      interval,
    };
  };
}
