import { i18nLanguage, languages } from "consts";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import commonsEN from "./en/commons.json";
import commonsES from "./es/commons.json";
import commonsPT from "./pt/commons.json";

import componentsEN from "./en/components.json";
import componentsES from "./es/components.json";
import componentsPT from "./pt/components.json";

import modalsEN from "./en/modals.json";
import modalsES from "./es/modals.json";
import modalsPT from "./pt/modals.json";

import authEN from "./en/auth.json";
import authES from "./es/auth.json";
import authPT from "./pt/auth.json";

import telemetryEN from "./en/telemetry.json";
import telemetryES from "./es/telemetry.json";
import telemetryPT from "./pt/telemetry.json";

import videoCallEN from "./en/videoCall.json";
import videoCallES from "./es/videoCall.json";
import videoCallPT from "./pt/videoCall.json";

import importEN from "./en/import.json";
import importES from "./es/import.json";
import importPT from "./pt/import.json";

import welcomeEN from "./en/welcome.json";
import welcomeES from "./es/welcome.json";
import welcomePT from "./pt/welcome.json";

import attendanceEN from "./en/attendance.json";
import attendanceES from "./es/attendance.json";
import attendancePT from "./pt/attendance.json";

import companyEN from "./en/company.json";
import companyES from "./es/company.json";
import companyPT from "./pt/company.json";

import dashboardEN from "./en/dashboard.json";
import dashboardES from "./es/dashboard.json";
import dashboardPT from "./pt/dashboard.json";

import auditEN from "./en/audit.json";
import auditES from "./es/audit.json";
import auditPT from "./pt/audit.json";

import reportEN from "./en/report.json";
import reportES from "./es/report.json";
import reportPT from "./pt/report.json";

import registrationEN from "./en/registration.json";
import registrationES from "./es/registration.json";
import registrationPT from "./pt/registration.json";

import administrativeEN from "./en/administrative.json";
import administrativeES from "./es/administrative.json";
import administrativePT from "./pt/administrative.json";

const resources = {
  pt: {
    commons: commonsPT,
    components: componentsPT,
    modals: modalsPT,
    auth: authPT,
    telemetry: telemetryPT,
    videoCall: videoCallPT,
    import: importPT,
    welcome: welcomePT,
    attendance: attendancePT,
    company: companyPT,
    dashboard: dashboardPT,
    audit: auditPT,
    report: reportPT,
    registration: registrationPT,
    administrative: administrativePT,
  },
  en: {
    commons: commonsEN,
    components: componentsEN,
    modals: modalsEN,
    auth: authEN,
    telemetry: telemetryEN,
    videoCall: videoCallEN,
    import: importEN,
    welcome: welcomeEN,
    attendance: attendanceEN,
    company: companyEN,
    dashboard: dashboardEN,
    audit: auditEN,
    report: reportEN,
    registration: registrationEN,
    administrative: administrativeEN,
  },
  es: {
    commons: commonsES,
    components: componentsES,
    modals: modalsES,
    auth: authES,
    telemetry: telemetryES,
    videoCall: videoCallES,
    import: importES,
    welcome: welcomeES,
    attendance: attendanceES,
    company: companyES,
    dashboard: dashboardES,
    audit: auditES,
    report: reportES,
    registration: registrationES,
    administrative: administrativeES,
  },
};

const languagePathDetector = {
  name: "languagePathDetector",
  lookup() {
    const path = window.location.pathname;
    const firstItemPath = path?.split("/")?.[0 || 1];
    const defaultLanguage = window.localStorage.getItem(i18nLanguage) || "";
    return languages.some((language) => language === firstItemPath)
      ? firstItemPath
      : defaultLanguage;
  },
};

const detector = new LanguageDetector();
detector.addDetector(languagePathDetector);

i18next
  .use(initReactI18next)
  .use(detector)
  .init({
    debug: false,
    ns: [
      "commons",
      "components",
      "modals",
      "auth",
      "telemetry",
      "videoCall",
      "import",
      "welcome",
      "attendance",
      "company",
      "dashboard",
      "audit",
      "report",
      "registration",
      "administrative",
    ],
    defaultNS: "commons",
    fallbackNS: "commons",
    fallbackLng: languages,
    detection: {
      order: ["languagePathDetector"],
      lookupLocalStorage: i18nLanguage,
    },
    interpolation: {
      escapeValue: false,
    },
    resources,
    returnNull: false,
  });

export default i18next;
