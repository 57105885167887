import { ValueType } from "components/select";
import { ModalParamsType } from "models";
import { useInterval } from "providers";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export type SelectIntervalParams = ModalParamsType;

export default function SelectIntervalViewModel({
  onClose,
}: SelectIntervalParams) {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState<number>();
  const interval = useInterval();
  const { status, intervalOpts, firstActiveItem } = interval;
  const { handleChangeStatus } = interval;
  const { t } = useTranslation("modals");

  const filteredList = useMemo(
    () => intervalOpts?.filter((i) => i.IdEventType !== firstActiveItem),
    [intervalOpts, firstActiveItem]
  );

  const onInit = useCallback(() => setSelected(status), [status]);

  const handleSelect = useCallback(
    (value: ValueType) => setSelected(value as number),
    []
  );

  const onSubmit = useCallback(async () => {
    setLoading(true);
    if (selected) await handleChangeStatus(selected).then(() => onClose());
    setLoading(false);
  }, [selected, handleChangeStatus, onClose]);

  return {
    loading,
    selected,
    list: filteredList,
    t,
    onInit,
    handleSelect,
    onSubmit,
  };
}
